export const PHONE_TWILIO = '+13239919994'
export const PHONE_TWILIO_TEST = '+14242310078'

export const FADEAWAY_FAMILY_ID = '01HMS1WXAJ3MB9JF5FVTC8W0M8'
export const BURRIS_FAMILY_ID = '01J3H2FN3PVFMX68971RBKTWVC'
export const TEST_ORGANIZATION_ID = '01HPFX0S7CKXSKR5TT898J9B2R'
export const APPLE_FAMILY_ID = '01HQP453JJC5M28EHFNYYWN6S5'

export const VOICE_PROJECT_ID = '01J1DG50DYKPAA0G0FTYT7V5J2'

export const JOHN_USER_ID = '01H4RGQX9DJ448R5GTQGTQTCRE'
export const ELLIOTT_USER_ID = '01H4RGR4AH17X4RV1KRW3KXN4T'
export const JUNHONG_USER_ID = '01H4RGRA8KCJ0860A4MM0CNW29'
export const CELINE_USER_ID = '01J3EGH6PZH7NZY8BDWX8HJR42'

export const SYSTEM_USER_ID = '01HA7SA2MT9MTFBN80X9QRN6F9'
export const TEST_BUYER_USER_ID = '01H4RGRFR1AC5Y0NDQKYMR74AQ'
export const APPLE_TEST_USER_ID = '01HADK14PY3Y7PWKWTPC930TNH'

export const PHONE_SYSTEM = '+15550000000'
export const PHONE_TEST = '+15551111111'
// export const PHONE_APPLE = '+15551234567'
export const PHONE_JOHN = '+19169904297'
export const PHONE_ELLIOTT = '+18048370119'
export const PHONE_JUNHONG = '+13102545002'
export const EMAIL_JOHN = 'john@fadeaway.com'
export const EMAIL_ELLIOTT = 'elliott@fadeaway.com'
export const EMAIL_TEST = 'test@fadeaway.com'

export const ADDRESS_ELLIOTT = {
  street: '3634 Wade St',
  city: 'Los Angeles',
  state: 'CA',
  zipcode: '90966',
}

export const ADDRESS_STRING_ELLIOTT = '3634 Wade St, Los Angeles, CA 90966'

export const ADDRESS_JOHN = {
  street: '418 Rialto Ave',
  city: 'Venice',
  state: 'CA',
  zipcode: '90291',
}

export const ADDRESS_STRING_JOHN = '418 Rialto Ave, Venice, CA 90291'

export const ADDRESS_GEOCODE_JOHN = {
  status: 'OK',
  results: [
    {
      address_components: [
        {
          long_name: '418',
          short_name: '418',
          types: ['street_number'],
        },
        {
          long_name: 'Rialto Avenue',
          short_name: 'Rialto Ave',
          types: ['route'],
        },
        {
          long_name: 'Venice',
          short_name: 'Venice',
          types: ['neighborhood', 'political'],
        },
        {
          long_name: 'Los Angeles',
          short_name: 'Los Angeles',
          types: ['locality', 'political'],
        },
        {
          long_name: 'Los Angeles County',
          short_name: 'Los Angeles County',
          types: ['administrative_area_level_2', 'political'],
        },
        {
          long_name: 'California',
          short_name: 'CA',
          types: ['administrative_area_level_1', 'political'],
        },
        {
          long_name: 'United States',
          short_name: 'US',
          types: ['country', 'political'],
        },
        {
          long_name: '90291',
          short_name: '90291',
          types: ['postal_code'],
        },
        {
          long_name: '4246',
          short_name: '4246',
          types: ['postal_code_suffix'],
        },
      ],
      formatted_address: '418 Rialto Ave, Venice, CA 90291, USA',
      place_id: 'ChIJsz8mKr-6woAR9wEvxIssanQ',
      geometry: {
        location: {
          lat: 33.9887619,
          lng: -118.4685372,
        },
        location_type: 'ROOFTOP',
        types: ['premise'],
      },
    },
  ],
}

export const PLACE_SEARCH_JOHN = {
  results: [
    {
      formatted_address: '418 Rialto Ave, Venice, CA 90291, USA',
      geometry: {
        location: {
          lat: 33.9887619,
          lng: -118.4685372,
        },
      },
      place_id: 'ChIJsz8mKr-6woAR9wEvxIssanQ',
    },
  ],
  status: 'OK',
}

export const ADDRESS_GEOCODE_ELLIOTT = {
  status: 'OK',
  results: [
    {
      address_components: [
        {
          long_name: '3634',
          short_name: '3634',
          types: ['street_number'],
        },
        {
          long_name: 'Wade Street',
          short_name: 'Wade St',
          types: ['route'],
        },
        {
          long_name: 'Mar Vista',
          short_name: 'Mar Vista',
          types: ['neighborhood', 'political'],
        },
        {
          long_name: 'Los Angeles',
          short_name: 'Los Angeles',
          types: ['locality', 'political'],
        },
        {
          long_name: 'Los Angeles County',
          short_name: 'Los Angeles County',
          types: ['administrative_area_level_2', 'political'],
        },
        {
          long_name: 'California',
          short_name: 'CA',
          types: ['administrative_area_level_1', 'political'],
        },
        {
          long_name: 'United States',
          short_name: 'US',
          types: ['country', 'political'],
        },
        {
          long_name: '90066',
          short_name: '90066',
          types: ['postal_code'],
        },
        {
          long_name: '3620',
          short_name: '3620',
          types: ['postal_code_suffix'],
        },
      ],
      formatted_address: '3634 Wade St, Los Angeles, CA 90066, USA',
      place_id: 'ChIJT6DwMPa6woARfrh_hw9Wzts',
      geometry: {
        location: {
          lat: 34.0053538,
          lng: -118.4406201,
        },
        location_type: 'ROOFTOP',
        types: ['premise'],
      },
    },
  ],
}

// For iOS preview
export const TEST_USER_HOME_ID = '01HB76871R23400EC3KAZ6S43T'
export const TEST_USER_SESSION_ID = '01HAG42AFFC2P741S79E0NCNYR'
export const TEST_USER_CONTACT_ID = '01HD526PW9M60QBBFE5E12HC5R'
export const TEST_USER_VERIFICATION_CODE = '2T381A'
export const APPLE_TEST_USER_VERIFICATION_CODE = '111999'
export const TEST_USER_CALL_ID = '01HEK5Z43Z8PK7NVWW2R97V392'

export const MEDIA_BATHROOM = {
  id: '01HCJWA43D12Q2YRW57AMTQ7QD',
  width: 5986,
  height: 3991,
  type: 'photo',
}

export const MEDIA_KITCHEN = {
  id: '01HCJWGGMRB0WDVS6RTWM30TEV',
  width: 3264,
  height: 4896,
  type: 'photo',
}

export const MEDIA_LIVING_ROOM = {
  id: '01HCJWKM885ENDRK53KG8QRASE',
  width: 4000,
  height: 6000,
  type: 'photo',
}

export const getRandomPhotoMedia = () => {
  const medias = [MEDIA_BATHROOM, MEDIA_KITCHEN, MEDIA_LIVING_ROOM]
  return medias[Math.floor(Math.random() * medias.length)]
}

import { create } from 'zustand'

interface RoomStoreState {
  token: string | null
  setToken: (token: string | null) => void

  url: string | null
  setUrl: (url: string | null) => void

  messages: {
    id: string
    user: string
    content: string
  }[]
  setMessages: (messages: { id: string; user: string; content: string }[]) => void
  appendMessage: (message: { id: string; user: string; content: string }) => void
}

export const useRoomStore = create<RoomStoreState>((set, get) => ({
  token: null,
  setToken: (token: string | null) => set({ token }),

  url: null,
  setUrl: (url: string | null) => set({ url }),

  messages: [],
  setMessages: (messages: { id: string; user: string; content: string }[]) => set({ messages }),
  appendMessage: (message: { id: string; user: string; content: string }) =>
    set({ messages: [...get().messages, message] }),
}))

import { HStack } from '@/components/HStack'
import { VStack } from '@/components/VStack'
import { __unsafe_useAuthStore } from '@/components/store/AuthStore'
import { useRoomStore } from '@/components/store/RoomStore'
import { Button } from '@/components/ui/button'
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable'
import { useCreateConversation } from '@/graphql/generated/query.types'
import { CELINE_USER_ID } from '@/lib/constants'
import { decoder } from '@/lib/decoder'
import {
  ControlBar,
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useConnectionState,
  useDataChannel,
  useParticipants,
  useRoomInfo,
  useTracks,
} from '@livekit/components-react'
import '@livekit/components-styles'
import dayjs from 'dayjs'
import { ConnectionState, Track } from 'livekit-client'
import React, { useEffect, useRef } from 'react'
import { decodeTime, ulid } from 'ulidx'

export default function IndexPage() {
  const token = useRoomStore(state => state.token)
  const url = useRoomStore(state => state.url)
  const setToken = useRoomStore(state => state.setToken)
  const setUrl = useRoomStore(state => state.setUrl)
  const messages = useRoomStore(state => state.messages)

  const [createConversation] = useCreateConversation()

  return (
    <>
      <main className="h-screen overflow-hidden">
        {token === null ? (
          <Button
            onClick={() => {
              createConversation({
                variables: {
                  creator: {
                    childId: CELINE_USER_ID,
                  },
                },
              }).then(async res => {
                const conversation = res.data?.createConversation
                if (conversation) {
                  const { accessToken, url } = await fetch(
                    `/api/livekit/token?roomName=${conversation.livekitRoomName}`,
                    {
                      credentials: 'include',
                      headers: { authorization: `bearer ${__unsafe_useAuthStore.getState().accessToken}` },
                    }
                  ).then(res => res.json())
                  setToken(accessToken)
                  setUrl(url)
                }
              })
            }}
          >
            Connect
          </Button>
        ) : (
          <LiveKitRoom
            video={true}
            audio={true}
            token={token}
            serverUrl={url ?? undefined}
            connectOptions={{ autoSubscribe: true }}
            data-lk-theme="default" // Use the default LiveKit theme for nice styles.
            style={{ height: '100dvh' }}
          >
            <ResizablePanelGroup direction="horizontal">
              <ResizablePanel defaultSize={80}>
                {/* Your custom component with basic video conferencing functionality. */}
                <MyVideoConference />
                {/* The RoomAudioRenderer takes care of room-wide audio for you. */}
                <RoomAudioRenderer />
                {/* Controls for the user to start/stop audio, video, and screen
          share tracks and to leave the room. */}
                <ControlBar />
                <MyComponent />
              </ResizablePanel>
              <ResizableHandle />
              <ResizablePanel defaultSize={20}>
                <VStack className="overflow-auto px-2 py-1 gap-2">
                  <MetadataView />
                  {messages.map(message => (
                    <VStack key={message.id} className="h-fit">
                      <HStack className="gap-1">
                        <div className="text-xs">{message.user}</div>
                        <div className="text-xs text-primary/80">{dayjs(decodeTime(message.id)).format('HH:mm')}</div>
                      </HStack>
                      <div className="text-sm text-pretty break-all">{message.content}</div>
                    </VStack>
                  ))}
                </VStack>
              </ResizablePanel>
            </ResizablePanelGroup>
          </LiveKitRoom>
        )}
      </main>
    </>
  )
}

function MyVideoConference() {
  // `useTracks` returns all camera and screen share tracks. If a user
  // joins without a published camera track, a placeholder track is returned.
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false }
  )
  return (
    <GridLayout tracks={tracks} style={{ height: 'calc(100vh - var(--lk-control-bar-height))' }}>
      {/* The GridLayout accepts zero or one child. The child is used
      as a template to render all passed in tracks. */}
      <ParticipantTile />
    </GridLayout>
  )
}

function MetadataView() {
  const participarnts = useParticipants()

  return (
    <VStack className="gap-2 ">
      {participarnts.map(p => (
        <React.Fragment key={p.identity}>
          <div className="text-sm text-primary/80 break-words">{p.identity}'s metadata</div>
          <div className="text-sm break-words">{p.metadata || '(empty)'}</div>
        </React.Fragment>
      ))}
    </VStack>
  )
}

function MyComponent() {
  const connectionState = useConnectionState()
  const prevConnectionState = useRef(connectionState)

  const setToken = useRoomStore(state => state.setToken)
  const setUrl = useRoomStore(state => state.setUrl)
  const appendMessage = useRoomStore(state => state.appendMessage)
  const setMessages = useRoomStore(state => state.setMessages)

  const { message: latestMessage, send } = useDataChannel('', msg => console.log('message received', msg))
  const room = useRoomInfo()
  const participants = useParticipants()

  // console.log({ room, participants })

  // useDataChannel('adult_in_view', msg => {
  //   const data = decoder.decode(msg.payload)
  //   appendMessage({
  //     id: ulid(),
  //     user: 'system',
  //     content: JSON.stringify({
  //       topic: 'adult_in_view',
  //       payload: data,
  //     }),
  //   })
  // })

  // useDataChannel('child_in_view', msg => {
  //   const data = decoder.decode(msg.payload)
  //   appendMessage({
  //     id: ulid(),
  //     user: 'system',
  //     content: JSON.stringify({
  //       topic: 'child_in_view',
  //       payload: data,
  //     }),
  //   })
  // })

  useDataChannel('adult_looking_at_screen', msg => {
    const data = decoder.decode(msg.payload)
    appendMessage({
      id: ulid(),
      user: 'system',
      content: JSON.stringify({
        topic: 'adult_looking_at_screen',
        payload: data,
      }),
    })
  })

  useDataChannel('child_looking_at_screen', msg => {
    const data = decoder.decode(msg.payload)
    appendMessage({
      id: ulid(),
      user: 'system',
      content: JSON.stringify({
        topic: 'child_looking_at_screen',
        payload: data,
      }),
    })
  })

  useEffect(() => {
    if (prevConnectionState.current !== connectionState) {
      // console.log('Previous connection state:', prevConnectionState.current)
      // console.log('Current connection state:', connectionState)
      prevConnectionState.current = connectionState

      if (connectionState === ConnectionState.Disconnected) {
        setToken(null)
        setUrl(null)
        setMessages([])
      }
    }
  }, [connectionState])

  useEffect(() => {
    setTimeout(() => {
      const encoder = new TextEncoder()
      const strData = JSON.stringify({ type: 'message', message: 'Hello from client' })
      const data = encoder.encode(strData)
      send(data, { reliable: false })
    }, 5000)
  }, [])

  return null
}
